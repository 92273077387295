/* You can add global styles to this file, and also import other style files */

@import "bulma/css/bulma.min.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
.container-side-adjust {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

.content-top-adjust {
  margin-top: 25px;
}

.main-page-content-top-adjust {
  margin-top: 45px;
}

.img-responsive {
  width: 100%;
}

.post-content-container {
  margin-top: 10px;
}

.post-content-text {
  margin-top: 30px;
  margin-bottom: 30px;
}

.post-content-text .post-step {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 30px;
  font-weight: bold;
}

.post-content-text .post-sub-step {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}

.post-content-text p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.is-center-text {
  text-align: center;
}

/* IMAGE Profile */

.circular--square {
  border-radius: 50%;
}

blockquote {
  font-family: Georgia, serif;
  font-size: 13px;
  font-style: italic;
  margin: 0.25em 0;
  padding: 0.35em 40px;
  line-height: 1.45;
  position: relative;
  color: #383838;
}

blockquote:before {
  display: block;
  padding-left: 10px;
  content: "\201C";
  font-size: 80px;
  position: absolute;
  left: -20px;
  top: -20px;
  color: #7a7a7a;
}

blockquote cite {
  color: #999999;
  font-size: 14px;
  display: block;
  margin-top: 5px;
}

blockquote cite:before {
  content: "\2014 \2009";
}

.number-step-bold {
  font-weight: bold;
}

.animated-bg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 300% 300%;
  background-image: linear-gradient(
    -45deg,
    #4FC1E9 0%,
    #4A89DC 25%,
    rgba(152, 68, 183, 1) 51%,
    #DA4453 100%
  );
  animation: AnimateBG 20s ease infinite;
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
